











































import { Vue, Component } from 'vue-property-decorator'
import cache from '@/common/cache';
import HeaderBox from '@/components/HeaderBox.vue'
import UploadImage from '@/components/UploadImage.vue'
import { resolve } from 'url';
import url from '@/common/url';

interface SubmitPickForm {
  materialsBill: string[]
  key_num: number
  remark: string
  image_urls: string[]
  uploadImages: any
}

@Component({
  components: {
    HeaderBox,
    UploadImage
  }
})
export default class SubmitPick extends Vue {
  submitPickForm: SubmitPickForm = {
    materialsBill: [],
    key_num: 1,
    remark: '',
    image_urls: [],
    uploadImages: [],
  }
  // uploadUrl: string = '/api/v1/source_handler/upload/image/pick_car_material'
  isTapDone = false

  listenChildImage (urls) {
    this.submitPickForm.uploadImages = urls;
  }

  mounted () {
    const { center_car_id } = this.$route.query;
    this.getReviewInfo(center_car_id);
  }

  async getReviewInfo (id) {
    const { code, data } = await this.$rest.carManage.getReviewInfo(id);
    if (code !== 0) return;
    if (!(data || {}).infos) return;
    const types = ['register_cert', 'license', 'insurance', 'id_card'];
    types.forEach((item, index) => {
      if (data.infos[item]) {
        this.submitPickForm.materialsBill.push(item)
      }
    })
    this.submitPickForm.image_urls = data.infos.image_urls;
    this.submitPickForm.uploadImages = data.infos.image_urls;
    this.submitPickForm.key_num = data.infos.key_num;
    this.submitPickForm.remark = data.infos.remark;
  }

  async handleSave () {
    const { center_car_id } = this.$route.query;
    if (this.submitPickForm.remark.length > 200) {
      this.$message.error('内容长度不能超过200个字');
      return;
    }

    if (this.submitPickForm.materialsBill) {
      const types = ['register_cert', 'license', 'insurance', 'id_card'];
      types.forEach((item, index) => {
        this.submitPickForm[item] = this.submitPickForm.materialsBill.includes(item) ? true : false;
      })
    }

    let form: any = Object.assign({}, this.submitPickForm);
    form.image_urls = this.formatImages(form.uploadImages);
    delete form.uploadImages;
    delete form.materialsBill;
    this.isTapDone = true;
    const { code } = await this.$rest.carManage.postReivewInfo(center_car_id, form);
    this.isTapDone = false;
    if (code !== 0) return
    this.$router.push('/carManage/enterWarehouse/index')
    this.$message.success('保存成功');
  }
}
