

















import { Vue, Component } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import cache from '@/common/cache';

@Component({
  props: {
    currentPageName: {
      type: String,
      default: ''
    }
  }
})
export default class HeaderBox extends Vue {
  @Mutation('UPDATE_USERINFO') getUserInfo
  @Getter('centerList') centerList
  @Getter('userName') userName
  @Getter('centerName') centerName
  dropdownVisible = false
  user_name = ''
  center_name = ''

  mounted () {
    this.getEmployeePermission();
  }
  
  async getEmployeePermission () {
    const resPermission = await this.$rest.login.getEmployeePermission();
    if (resPermission.code !== 0) return;
    cache.setLocalStorageData('auth_list', resPermission.data);
  }

  handleMouseMove () {
    this.dropdownVisible = true;
  }

  handleMouseLeave () {
    this.dropdownVisible = false;
  }

  async handleLogout (val) {
    if (val === 'change') {
      this.$router.push('/choosingCenter')
    } else {
      const { code } = await this.$rest.login.logout();
      if (code !== 0) return;
      cache.setLocalStorageData('access_token', '');
      cache.setLocalStorageData('auth_list', '');
      this.$store.commit('UPDATE_USERINFO', {});
      this.$router.push('/login');
    }
  }
}
