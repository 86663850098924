
































import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { Upload } from 'element-ui';
import cache from '@/common/cache';

@Component({
  props: {
    type: {
      type: String,
      default: ''
    },
    // uploadUrl: {
    //   type: String,
    //   default: ''
    // },
    marginLeft: {
      type: Number,
      default: 0
    }
  }
})
export default class UploadImage extends Vue {
  @Prop(Array) uploadImages!: Array<any>
  @Prop(Number) maxLength!: Number
  @Prop({default: ''}) type: String

  // headers = {
  //   Authorization: cache.getLocalStorageData('access_token')
  // }
  headers = {}
  uploadUrl = ''
  updateUrls = []
  uploadExtraData = {}
  firstErrTips = false // 控制错误提示一次

  mounted () {
    this.updateUrls = this.uploadImages || [];
    console.log('this.updateUrls', this.updateUrls)
  }

  handleImgClose (index) {
    this.firstErrTips = false;
    this.updateUrls.splice(index, 1);
  }

  handleUploadSuccess (res) {
    if (this.firstErrTips) return;
    if (this.updateUrls.length === this.maxLength) {
      this.firstErrTips = true;
      this.$message.error(`最多支持上传${this.maxLength}张`);
      return
    }
    this.firstErrTips = false;
    const { thumbnail_image, upload_image } = res.data;
    this.updateUrls.push(res.data[0]);
    console.log('this.updateUrls', this.updateUrls)
    this.$emit('child-image', this.updateUrls);
  }

  checkUploadImgRules (file) {
    const typeList = ['image/jpeg', 'image/jpg', 'image/png', 'image/bmp']
    const isType = typeList.includes(file.type)
    const isLt10M = (file.size / 1024 / 1024) < 10
    if (!isType) this.$message.error('仅支持上传格式为jpg、png、jpeg、bmp的图片')
    if (!isLt10M) this.$message.error('上传图片不能大于10M')
    return isType && isLt10M
  }

  beforeUpload(file) {
    return new Promise(async (resolve, reject) => {
      if (!this.checkUploadImgRules(file)) reject(false);
      const { code, data } = await this.$rest.global.getUploadConfig(this.type);
      console.log('beforeUpload', data)

      if (code !== 0) return false;

      this.headers = {
        'lixin-token': data.token,
      }

      this.uploadUrl = data.url;

      this.uploadExtraData = {
        'upload-type': 'image',
        count: '1',
        path1: data.paths[0],
      };
      resolve(true);
    });
  }

  @Watch('uploadImages', { deep: true })
  showUploadImages (value) {
    this.firstErrTips = false;
    console.log('value:', value)
    this.updateUrls = [...value] || [];
  }
}
